import React from "react"
import TechnicalDebtImage from "../content/assets/images/the-truth-about-technical-debt.jpeg"
import MicroManagement from "../content/assets/images/understanding-micromanagement-in-an-agile-environment.jpeg"
import AgileFrustration from "../content/assets/images/developers-do-not-like-agile-environments.jpeg"

export default function Example() {
    const posts = [
        {
            title : "The Truth About Technical Debt In Agile Environments",
            excerpt : "Technical debt has become a battleground between the development team and the business…",
            image : TechnicalDebtImage,
            alt : "An image of a wall with the phrase until debt tear us apart stenciled upon it.",
            href : "/the-truth-about-technical-debt-in-agile-environments"

        },
        { 
            title : "Understanding micromanagment in an agile environment",
            excerpt : "One of the 3 most common complaints I've heard from development teams working in agile…",
            image : MicroManagement,
            alt : "A man in a pinstripe suit looking through a magnifying glass",
            href : "/understanding-micromanagement-in-an-agile-environment"

        },
        { 
            title : "Developers do not like agile environments, should tech leaders be worried?",
            excerpt : "A lot of companies are committed to delivering regular value to customers at pace. They…",
            image : AgileFrustration,
            alt : "A bearded man cradling his head whilst shouting in frustration",
            href : "/developers-do-not-like-agile-should-tech-leaders-be-worried"
        }
    ];

    return (
        <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
                <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                        Featured Resources
                    </h2>
                    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                        Learn the management techniques that are helping me to build high performing tech teams.
                    </p>
                </div>
                <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    {posts.map(p => {
                        console.log(p)
                        return (
                            <div
                                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                            >
                                <div className="flex-shrink-0">
                                    <img
                                        className="h-48 w-full object-cover"
                                        src={p.image}
                                        alt={p.alt}
                                    />
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <a href={p.href} className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">
                                                {p.title} 
                                            </p>
                                            <p className="mt-3 text-base text-gray-500">
                                                {p.excerpt}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

